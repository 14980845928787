"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.base = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _userAgent = require("../../tools/userAgent");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var base = function base(theme) {
  theme = {
    colors: { 
      primary: "#005eaa !important",
      primaryHover: "#196ECF",  
      secondary: "#114D91",  
      tertiary: "#EBF3FD",  
      focus: "#196ECF",  
      black: "#000000",  
      white: "#FFFFFF",  
      info: "#196ECF",  
      success: "#0F880C",  
      error: "#D0021C",  
      warning: "#705000",  
      gray50: "#FFFFFF",  
      gray100: "#FEFEFE",  
      gray150: "#F1F1F1",  
      gray200: "#E5E5E5",  
      gray250: "#D8D8D8",  
      gray300: "#CBCBCB",  
      gray350: "#BFBFBF",  
      gray400: "#B2B2B2",  
      gray450: "#A5A5A5",  
      gray500: "#989898",  
      gray550: "#7F7F7F",  
      gray600: "#666666",  
      gray650: "#595959",  
      gray700: "#4C4C4C",  
      gray750: "#404040",  
      gray800: "#333333",  
      gray850: "#262626",  
      gray900: "#191919",  
      gray950: "#0D0D0D",  
      gray1000: "#000000",  
      red50: "#FEBBC3",  
      red100: "#FEA6B1",  
      red150: "#FE919F",  
      red200: "#FE7C8D",  
      red250: "#FE677A",  
      red300: "#FD5368",  
      red350: "#FD3E56",  
      red400: "#FD2944",  
      red450: "#FD1432",  
      red500: "#FA0222",  
      red550: "#D0021C",  
      red600: "#A60216",  
      red650: "#920114",  
      red700: "#7D0111",  
      red750: "#68010E",  
      red800: "#53010B",  
      red850: "#3E0108",  
      red900: "#2A0006",  
      red950: "#150003",  
      red1000: "#000000",  
      orange50: "#FAE8DF",  
      orange100: "#F6DACC",  
      orange150: "#F3CCB9",  
      orange200: "#F0BEA5",  
      orange250: "#ECB092",  
      orange300: "#E9A27F",  
      orange350: "#E6946C",  
      orange400: "#E28759",  
      orange450: "#DF7945",  
      orange500: "#DC6B32",  
      orange550: "#C05621",  
      orange600: "#9A451A",  
      orange650: "#863C17",  
      orange700: "#733414",  
      orange750: "#602B11",  
      orange800: "#4D220D",  
      orange850: "#3A1A0A",  
      orange900: "#261107",  
      orange950: "#130903",  
      orange1000: "#000000",  
      yellow50: "#FFF1CD",  
      yellow100: "#FFEAB7",  
      yellow150: "#FFE4A1",  
      yellow200: "#FFDE8B",  
      yellow250: "#FFD775",  
      yellow300: "#FFD15F",  
      yellow350: "#FFCB49",  
      yellow400: "#FFC434",  
      yellow450: "#FFBE1E",  
      yellow500: "#FFB808",  
      yellow550: "#705000",  
      yellow600: "#AF7D00",  
      yellow650: "#996D00",  
      yellow700: "#835E00",  
      yellow750: "#6E4E00",  
      yellow800: "#583E00",  
      yellow850: "#422F00",  
      yellow900: "#2C1F00",  
      yellow950: "#161000",  
      yellow1000: "#000000",  
      green50: "#4CEF48",  
      green100: "#3FEE3A",  
      green150: "#31EC2D",  
      green200: "#24EB1F",  
      green250: "#19E714",  
      green300: "#18DA13",  
      green350: "#16CC12",  
      green400: "#15BE11",  
      green450: "#13B110",  
      green500: "#12A30E",  
      green550: "#0F880C",  
      green600: "#0C6D0A",  
      green650: "#0A5F08",  
      green700: "#095207",  
      green750: "#074406",  
      green800: "#063605",  
      green850: "#042904",  
      green900: "#031B02",  
      green950: "#010E01",  
      green1000: "#000000",  
      teal50: "#F1FBFA",  
      teal100: "#DFF5F4",  
      teal150: "#CDEFEE",  
      teal200: "#BBEAE7",  
      teal250: "#AAE4E1",  
      teal300: "#98DFDB",  
      teal350: "#86D9D5",  
      teal400: "#74D3CF",  
      teal450: "#62CEC8",  
      teal500: "#51C8C2",  
      teal550: "#38B2AC",  
      teal600: "#2D8E8A",  
      teal650: "#277D78",  
      teal700: "#226B67",  
      teal750: "#1C5956",  
      teal800: "#164745",  
      teal850: "#113534",  
      teal900: "#0B2422",  
      teal950: "#061211",  
      teal1000: "#000000",  
      blue50: "#EBF3FD",  
      blue100: "#D6E7FA",  
      blue150: "#C1DBF8",  
      blue200: "#ADCEF5",  
      blue250: "#98C2F3",  
      blue300: "#83B6F0",  
      blue350: "#6EAAEE",  
      blue400: "#5A9EEB",  
      blue450: "#4591E9",  
      blue500: "#3085E6",  
      blue550: "#196ECF",  
      blue600: "#1458A6",  
      blue650: "#114D91",  
      blue700: "#0F427C",  
      blue750: "#0C3768",  
      blue800: "#0A2C53",  
      blue850: "#07213E",  
      blue900: "#051629",  
      blue950: "#020B15",  
      blue1000: "#000000",  
      cyan50: "#FFFFFF",  
      cyan100: "#ECFBFE",  
      cyan150: "#D4F6FD",  
      cyan200: "#BDF1FC",  
      cyan250: "#A6EDFB",  
      cyan300: "#8EE8FA",  
      cyan350: "#77E3F9",  
      cyan400: "#5FDEF8",  
      cyan450: "#48D9F6",  
      cyan500: "#31D5F5",  
      cyan550: "#0BC5EA",  
      cyan600: "#099EBB",
      cyan600: "#099EBB",  
      cyan650: "#088AA4",  
      cyan700: "#07768C",  
      cyan750: "#056275",  
      cyan800: "#044F5E",  
      cyan850: "#033B46",  
      cyan900: "#02272F",  
      cyan950: "#011417",  
      cyan1000: "#000000",  
      purple50: "#FFFFFF",  
      purple100: "#F8F5FD",  
      purple150: "#E8DFFA",  
      purple200: "#D9CAF7",  
      purple250: "#C9B4F3",  
      purple300: "#BA9FF0",  
      purple350: "#AA89EC",  
      purple400: "#9B74E9",  
      purple450: "#8B5EE6",  
      purple500: "#7C49E2",  
      purple550: "#5E22D7",  
      purple600: "#4B1BAC",  
      purple650: "#421897",  
      purple700: "#381481",  
      purple750: "#2F116C",  
      purple800: "#260E56",  
      purple850: "#1C0A41",  
      purple900: "#13072B",  
      purple950: "#090316",  
      purple1000: "#000000",  
      pink50: "#FDF2F8",  
      pink100: "#FBDDEC",  
      pink150: "#F8C8DF",  
      pink200: "#F6B3D3",  
      pink250: "#F39EC7",  
      pink300: "#F189BB",  
      pink350: "#EE74AF",  
      pink400: "#EC5EA3",  
      pink450: "#E94997",  
      pink500: "#E7348B",  
      pink550: "#D31973",  
      pink600: "#A9145C",  
      pink650: "#941150",  
      pink700: "#7F0F45",  
      pink750: "#6A0C39",  
      pink800: "#540A2E",  
      pink850: "#3F0722",  
      pink900: "#2A0517",  
      pink950: "#15020B",  
      pink1000: "#000000"  
    },
    fontWeights: {  
      normal: 400,  
      medium: 600,  
      bold: 700,  
    },  
    breakpoints: {  
      sm: "480px",  
      md: "768px",  
      lg: "992px",  
      xl: "1280px",  
    },  
    fontSizes: {
      size100: "8px",
      size150: "9px",
      size200: "10px",
      size250: "11px",
      size300: "12px",
      size350: "13px",
      size400: "14px",
      size450: "15px",
      size500: "16px",
      size550: "17px",
      size600: "18px",
      size650: "19px",
      size700: "20px",
      size750: "22px",
      size800: "24px",
      size850: "28px",
      size900: "36px",
      size950: "48px",
      size1000: "64px"
    }
  }

  var _theme$colors = theme.colors,
      textColor = _theme$colors.gray850,
      pageBackgroundColor = _theme$colors.gray100,
      primaryColor = _theme$colors.primary,
      secondaryColor = _theme$colors.secondary,
      focusColor = _theme$colors.focus;
  var focusStyle;

  if (!_userAgent.userAgent.isIE) {
    focusStyle = {
      ':focus': {
        boxShadow: "0 0 0 1px #ffffff, 0 0 3px 4px ".concat(focusColor),
        outline: '1px dotted transparent',
        outlineOffset: '1px'
      }
    };
  }

  return _objectSpread(_objectSpread({
    '*, *:before, *:after': {
      boxSizing: 'border-box',
      padding: 0,
      margin: 0
    },
    html: {
      fontSize: theme.fontSizes.size500
    },
    body: {
      fontFamily: 'Arial, sans-serif',
      'font-size': theme.fontSizes.size500,
      'font-weight': theme.fontWeights.normal,
      'line-height': '24px',
      color: textColor,
      backgroundColor: pageBackgroundColor,
      '-webkit-touch-callout': 'none',
      fontSmoothing: 'antialiased',
      '-webkit-font-smoothing': 'antialiased',
      '*': {
        '-webkit-tap-highlight-color': 'rgba(255, 255, 255, 0)'
      }
    }
  }, focusStyle), {}, {
    'h1, h2, h3, h4, h5, h6, label, p, button, abbr, a, small': {
      fontSmoothing: 'antialiased',
      '-webkit-font-smoothing': 'antialiased',
      textSizeAdjust: '100%'
    },
    p: {
      marginBottom: '16px'
    },
    h1: {
      fontSize: theme.fontSizes.size850,
      fontWeight: theme.fontWeights.medium,
      lineHeight: '48px'
    },
    h2: {
      fontSize: theme.fontSizes.size800,
      fontWeight: theme.fontWeights.medium,
      lineHeight: '36px'
    },
    h3: {
      fontSize: theme.fontSizes.size700,
      fontWeight: theme.fontWeights.medium,
      lineHeight: '28px'
    },
    h4: {
      fontSize: theme.fontSizes.size500,
      fontWeight: theme.fontWeights.normal,
      lineHeight: '24px'
    },
    h5: {
      fontSize: theme.fontSizes.size400,
      fontWeight: theme.fontWeights.normal,
      lineHeight: '20px'
    },
    'h6, small': {
      fontSize: theme.fontSizes.size300,
      fontWeight: theme.fontWeights.normal,
      lineHeight: '20px'
    },
    a: {
      textDecoration: 'none',
      color: primaryColor,
      '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
      '&:hover, &:active': {
        textDecoration: 'underline',
        color: secondaryColor
      }
    },
    'button, input, optgroup, select, textarea': {
      border: 'none',
      outline: 'none',
      appearance: 'none',
      '-webkit-touch-callout': 'none',
      '-webkit-tap-highlight-color': 'rgba(255, 255, 255, 0)'
    },
    button: {
      cursor: 'pointer',
      'background-color': 'transparent'
    },
    '[disabled]': {
      cursor: 'default'
    },
    img: {
      maxWidth: '100%',
      height: 'auto'
    }
  });
};

exports.base = base;