/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { Button } from '@hhs/ui/src/base/Button';
import { event } from '@abyss/web/tools/event';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { styles } from '../LandingPage.styles';
import { getFeature } from '@hhs/api/src/tools/features';

export const Tab4 = () => {
  const classes = useStyles(styles);
  const hooText = getFeature('HRSA_PRF_HOO');

  const gotToFeedBack = () => {
    event('BUTTON_CLICK', {
      pageName: 'care unauthenticated page- resources and support',
      linkName: 'Submit Feedback',
    });
    window.open(
      'https://providercaresus.iad1.qualtrics.com/jfe/form/SV_4YmaQInA4s79rEx',
      '_blank'
    );
  };
  const handleTutorial = () => {
    event('LINK_CLICK', {
      pageName: 'care unauthenticated page- resources and support',
      linkName: 'Visit the CARES Provider Relief Fund User Guide',
    });
  };

  const handleAdditionInfo = () => {
    event('LINK_CLICK', {
      pageName: 'care unauthenticated page- resources and support',
      linkName: 'https://hrsa.gov/provider-relief',
    });
  };
  return (
    <React.Fragment>
      <div className={classes.textContent}>
        <div className={classes.tab4Heading}>Training Resources</div>
        <br />
        <div>
          <div>
            <a
              onClick={handleTutorial}
              href="https://chameleoncloud4.io/review/prod/d8ba4052-c0e9-449f-81d0-89ed55eef659"
              target="_blank"
            >
              HRSA Provider Relief Fund Payment Portal User Guide
            </a>
          </div>
          <br />
          <div>
            Please visit{' '}
            <a
              onClick={handleAdditionInfo}
              href="https://hrsa.gov/provider-relief"
              target="_blank"
            >
              https://hrsa.gov/provider-relief
            </a>{' '}
            for additional information regarding this program.
          </div>
        </div>
        <br />
        <div className={classes.tab4Heading}>Customer Support</div>
        <br />
        <div>
          <div>
            Our service staff members are available to provide real-time
            technical support, as well as service and payment support. {hooText}
          </div>
          <br />
          <div>
            <b>Provider Support Line</b>: (866) 569-3522; for TTY dial 711.
          </div>
        </div>
        <br />

        <div className={classes.tab4Heading}>We're Listening</div>
        <br />
        <ul>
          <li>
            We are committed to making the HRSA Provider Relief Program as
            simple and accessible as possible. We are also monitoring your
            inquiries and working hard to answer your questions. Let us know how
            we’re doing, and we’ll update our resources based on your input.
          </li>
        </ul>
      </div>

      <Button className={classes.feedBackButton} onClick={gotToFeedBack}>
        Submit Feedback
        <span className={classes.visuallyHide}>Opens in a new window</span>{' '}
        <IconMaterial size={16} color="#fff" alt="" icon="launch" />
      </Button>
    </React.Fragment>
  );
};
